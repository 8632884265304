import Jikina from '../components/jikina';
import { Btn, Underline, Touch } from '../components/smallComps'
import './about.css'
import { useEffect} from 'react'
import { motion} from 'framer-motion';

export default function About(){
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);

    return(
        <>
            <div className='wrapper'>
                <article className='aboutLanding'>
                    <main>
                        <h1>Mission to empower our Nation in 
                        <br/>Financial Literacy among the Youth</h1>
                        <div className='aboutLandinBtn'>
                            <Btn
                            text={"Enroll Now"}
                            link={'#touch'}/>
                        </div>
                    </main>
                    <figure>
                        <img src='./images/aboutLanding.png' alt='About Kiddofin'/>
                    </figure>
                </article>
                <article className='aboutUs'>
                    <header>
                        <h2>About Us</h2>
                        <Underline
                        width={60}/>
                    </header>
                    <p>
                        At Kiddofin, a part of “TuskerRayz Consultation Serivices (OPC) Private Limited” founded on November 1, 2023, 
                        we're passionately dedicated to empowering the young minds of Gen Z and Gen Alpha within today's ever-changing financial landscape. 
                        These young individuals, born into a digital world, are the future innovators and leaders. Our educational initiatives, 
                        inspired by the insights of the National Centre for Financial Education (NCFE) and Banking, Financial Services, and Insurance (BFSI) experts, 
                        transcend traditional financial literacy. <br/><br/>
                        We're not just teaching them about money; we're equipping them with 
                        essential life skills that teach them how their financial choices can profoundly impact their lives and the world around them. 
                        Our mission at Kiddofin extends beyond mere education; it's about creating a nurturing community for these young individuals. 
                        In a world rife with financial complexities, we guide them to dream big, embrace informed risks, and approach their financial futures with both optimism and responsibility. 
                        By doing so, we're not just preparing them for tomorrow; we're helping them shape it, nurturing a generation that's financially astute, ethically grounded, 
                        and poised to make a significant impact. <br/><br/>
                        We are dedicated to giving back through our CSR activities, 
                        which include providing free financial literacy education to rural and underprivileged individuals, 
                        as well as engaging in book and food distribution programs.
                    </p>
                </article>
            </div>
            <article className='MisVis'>
                <motion.div className='MisVisContainer'
                    initial={{x:-200, opacity:0}}
                    whileInView={{x:0, opacity:1}}
                    transition={{duration:.5, delay:.5}}
                >
                    <main>
                        <h2>Vision</h2>
                        <p>To forge a future where every Indian child is a cornerstone in our nation's ascent to global economic leadership, making a profound impact on society with their unmatched financial acumen.</p>
                    </main>
                    <figure>
                        <img src='./images/vision.png' alt='vision'/>
                    </figure>
                </motion.div>
                <motion.div className='MisVisContainer'
                    initial={{x:200, opacity:0}}
                    whileInView={{x:0, opacity:1}}
                    transition={{duration:.5, delay:.5}}
                >
                    <main>
                        <h2>Mission</h2>
                        <p>To deliver cutting-edge financial education that equips students to master complex financial dynamics and drive meaningful change</p>
                    </main>
                    <figure>
                        <img src='./images/mission.png' alt='vision'/>
                    </figure>
                </motion.div>
            </article>
            <div className='wrapper'>
                <article className='approach'>
                    <header>
                        <h2>Our Approach</h2>
                        <Underline
                        width={40}/>
                    </header>
                    <main>
                        <p>Our primary goal is to offer cost-effective financial literacy. 
                            We understand the significance of financial education for both students and parents. 
                            By making this knowledge accessible and affordable, we aim to enrich individual talents, 
                            hobbies, and passions, transforming them into viable business opportunities. 
                            This initiative benefits not only the younger generation but also provides senior community members 
                            the opportunity to impart their knowledge and experiences.

                            <br/><br/>At the core of our strategy is the innovative use of artificial intelligence, 
                            emphasizing our belief that academics should be service-oriented rather than profit-driven. 
                            AI enables us to deliver high-quality, personalized learning experiences at a fraction of traditional costs.
                        </p>
                        <figure>
                            <img src='./images/approach.png' alt='Approach Kiddofin'/>
                        </figure>
                    </main>
                </article>
                <article className='aboutUs otherAbout'>
                    <header>
                        <h2>Leadership</h2>
                        <Underline
                        width={60}/>
                    </header>
                    <p>
                         Our Director, Mangaiyarkarasi Manoharan, exemplifies Kiddofin's vision. Her rich background in banking and software, 
                        combined with her status as a Chartered Banker from the United Kingdom and a Certified Information Systems Auditor, 
                        uniquely qualifies her to guide our programs. Her expertise effectively bridges traditional financial practices with modern technological advancements, 
                        ensuring our programs are both educational and relevant.
                    </p>
                </article>
                <article className='aboutUs otherAbout'>
                    <header>
                        <h2>Educational support</h2>
                        <Underline
                        width={18}/>
                    </header>
                    <p>
                        Integral to our programs is the support from trained educators who specialize in making financial concepts interesting and understandable for kids. 
                        These educators use a variety of interactive and engaging methods to bring financial education to life, making it both fun and informative.
                        
                        <br/><br/>In summary, Kiddofin is more than just a financial literacy program; it's a movement towards creating a financially aware and empowered society. 
                        By integrating cutting-edge technology with practical financial education and the support of skilled educators, we are setting the stage for a future where 
                        financial literacy is a universal skill, accessible and beneficial to everyone.
                    </p>
                </article>
            </div>
            <div className='jikinaContainer aboutJikina'>
                <Jikina/>
            </div>
            <div className='wrapper'>
                <Touch/>
            </div>
        </>
    )
}