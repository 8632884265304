import './jikina.css'
import { Btn } from './smallComps'
import { motion} from 'framer-motion';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export default function Jikina(){

    // To Choose NavBar
    const [navbar, setNavbar] = useState(true)
    const navSelect = () =>{
        if(window.innerWidth<768){
            setNavbar(false)
        }else{
            setNavbar(true)
        }
    }
    useEffect(() => {
        if (window.innerWidth<768){
            setNavbar(false)
        }else{
            setNavbar(true)
        }
            window.addEventListener('resize', navSelect);
            window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
            window.removeEventListener('resize', navSelect);
        };
    }, []);


//  Framer Reveal FadeUp 
    const [isVisible, setIsVisible] = useState(false);
    const { ref, inView } = useInView();
   
    useEffect(() => {
      setIsVisible(inView);
    }, [inView]);

    const row1A=["dot","dot green","dot","dot avatar1","dot","dot blue","dot avatar1","dot sky","dot","dot ",]
    const row1B=["dot pista","dot ","dot green","dot avatar1","dot","dot avatar2","dot ","dot sky ","dot","dot green",]
    const row1C=["dot avatar3", "dot", "dot", "dot blue", "dot", "dot", "dot", "dot pista", "dot", "dot blue", ]
    
    const row2A=["dot avatar1", "dot", "dot", "dot pista", "dot blue", "dot", "dot avatar3", "dot", "dot sky", "dot", ]
    const row3A=["dot", "dot green", "dot avatar2", "dot pista", "dot", "dot", "dot", "dot blue", "dot ", "dot avatar3", ]
    const row4A=["dot blue", "dot", "dot pista", "dot avatar3", "dot", "dot green", "dot", "dot avatar2", "dot ", "dot sky", ]
    
    const row2B=["dot ", "dot", "dot", "dot sky", "dot", "dot blue", "dot avatar1", "dot", "dot green", "dot", ]
    const row3B=["dot avatar1", "dot", "dot pista", "dot avatar2", "dot", "dot", "dot blue", "dot sky", "dot", "dot", ]
    const row4B=["dot ", "dot blue", "dot", "dot green", "dot", "dot", "dot avarat3", "dot pista", "dot", "dot", ]

    const row5A=["dot avatar3", "dot", "dot", "dot blue", "dot", "dot", "dot", "dot pista", "dot", "dot blue", ]
    const row5B=["dot", "dot green", "dot avatar2", "dot pista", "dot", "dot", "dot", "dot blue", "dot ", "dot avatar3", ]
    const row5C=["dot","dot green","dot","dot avatar1","dot","dot blue","dot avatar1","dot sky","dot","dot ",]

    if(navbar){
        return(
            <>
            <div className='jikina' initial='hidden' ref={ref}>
                <div className='jikinaBG'>
                    <div className='jikinaRow'>
                        <div className='jikinaSection'>
                            {row1A.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"} key={key}/>))}
                        </div>
                        <div className='jikinaSection'>
                            {row1B.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"} key={key}/>))}
                        </div>
                        <div className='jikinaSection'>
                            {row1C.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"} key={key}/>))}
                        </div>                    
                    </div> 
    {/* ========================================================================  */}
                    <div className='jikinaRow'>
                        <div className='jikinaCol'>
                            <div className='jikinaSection' style={{transform:"translateX(0px)", marginRight:"500px"}}>
                                {row2A.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain what"}key={key}/>))}
                            </div>
                            <div className='jikinaSection' style={{transform:"translateX(-50px)"}}>
                                {row3A.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"} key={key}/>))}
                            </div>
                            <div className='jikinaSection' style={{transform:"translateX(0px)"}}>
                                {row4A.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"} key={key}/>))}
                            </div>
                        </div>
    
                        {/* <div className='jikinaCol'>
                            <div className='what'>
    
                            </div>
                        </div> */}
    
                        <div className='jikinaCol'>
                            <div className='jikinaSection' style={{transform:"translateX(0px)"}}>
                                {row2B.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"} key={key}/>))}
                            </div>
                            <div className='jikinaSection' style={{transform:"translateX(50px)"}}>
                                {row3B.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"} key={key}/>))}
                            </div>
                            <div className='jikinaSection' style={{transform:"translateX(0px)"}}>
                                {row4B.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"} key={key}/>))}
                            </div>
                        </div>
                    </div>
    {/* ============================================================== */}
                    <div className='jikinaRow'>
                        <div className='jikinaSection'>
                            {row5A.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"} key={key}/>))}
                        </div>
                        <div className='jikinaSection'>
                            {row5B.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"} key={key}/>))}
                        </div>
                        <div className='jikinaSection'>
                            {row5C.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"} key={key}/>))}
                        </div>                    
                    </div> 
                </div>
                <div className='findContainer'>
                    <div className='find'>
                        <h2>Nextgen FinαnZ</h2>
                        <p>Lets Make Sure our Next Generation gets the Knowledge of Financial Freedom</p>
                        <div className='enrollBtn'>
                            <Btn
                            text={"Enroll Now"}
                            link={'#touch'}/>
                        </div>                    
                    </div>
                </div>
            </div>
            </>
        )
    }else{
        return(
            <>
            <div className='jikina' initial='hidden' ref={ref}>
                <div className='jikinaBG'>
                    <div className='jikinaRow'>
                        <div className='jikinaSection'>
                            {row5A.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"}key={key}/>))}
                        </div>                  
                    </div> 
                    <div className='jikinaRow' style={{transform: "translateX(-25px)"}}>
                        <div className='jikinaSection'>
                            {row3B.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"}key={key}/>))}
                        </div>                  
                    </div>     
                </div>
                <div className='findContainer'>
                    <div className='find'>
                        <h2>Nextgen FinαnZ</h2>
                        <p>Lets Make Sure our Next Generation gets the Knowledge of Financial Freedom</p>
                        <div className='enrollBtn'>
                            <Btn
                            text={"Enroll Now"}
                            link={'#touch'}/>
                        </div>                    
                    </div>
                </div>
                <div className='jikinaBG'>
                    <div className='jikinaRow'>
                        <div className='jikinaSection'>
                            {row1B.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"}key={key}/>))}
                        </div>                  
                    </div> 
                    <div className='jikinaRow' style={{transform: "translateX(-25px)"}}>
                        <div className='jikinaSection'>
                            {row2B.map((item, key)=>(<motion.div className={isVisible?item:"dotPlain"}key={key}/>))}
                        </div>                  
                    </div>     
                </div>
            </div>
            </>
        )
    }
}