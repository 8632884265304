import { Link } from 'react-router-dom'
import './footer.css'

export default function Footer(){
    return(
        <>
            <div className='footer'>
                <div className='colMain'>
                    <header>
                        <h2>Kiddofin</h2>
                    </header>
                    <div className='addressFooter'>
                        <h3>Registered Office :</h3>
                        <p>TuskerRayz Consultation Services (OPC) Private Limited, Adv. VR Manoharan Building, Channel Road, Melur, Madurai- TN 625106.</p>                    
                    </div>
                    <div className='addressFooter'>
                        <h3>KiDDOFIN Office:</h3>
                        <p>54/1 ,Sri Ragavendra Nilaya, Vinayakanagar, Beside Mynah square apple city, Near Prestige Lakeside Apartment, Varthur Sarjapur road, Gunjur,Bengaluru, Karnataka 560087</p>                    
                    </div>
                    <div className='contactFooter' style={{marginTop:"3rem"}}>
                        <figure>
                            <img src='../images/mail.svg' alt='mail'/>
                        </figure>
                        <h3>Mail Us</h3>
                        <p>Info@ kiddofin.com<br/>tuskerrayz@gmail.com</p>
                    </div>                
                    <div className='contactFooter'>
                        <figure>
                            <img src='../images/phone.svg' alt='mail'/>
                        </figure>
                        <h3>Call Us</h3>
                        <p>+91 63665 62433<br/>+91 8270982433</p>
                    </div>
                    <div className='contactFooter footerIcons'>
                        <figure>
                            <a href='https://www.facebook.com/'>
                                <img src='./images/fb.svg' alt='FB'/>
                            </a>                        
                        </figure>
                        <figure>
                            <a href='https://www.instagram.com/nextgen_finanz/'>
                                <img src='./images/insta.svg' alt='FB'/>
                            </a>
                        </figure>
                        <figure>
                            <a href='https://x.com/?lang=en'>
                                <img src='./images/x.svg' alt='FB'/>
                            </a>
                        </figure>
                        <figure>
                            <a href='https://www.youtube.com/@KiddoFin/videos'>
                                <img src='./images/youtube.svg' alt='FB'/>
                            </a>
                        </figure>
                    </div>
                </div>
                <div className='col'>
                    <Link to={'/'}>Home</Link>
                    <Link to={'/about'}>About Us</Link>
                    <Link to={'/programme'}>Programme</Link>
                    <Link to={'/source'}>Source</Link>
                    <Link to={'/other'}>Other</Link>
                    <Link to={'/contact'}>Contact Us</Link>
                </div>
                <div className='col'>
                    <Link to={'https://www.linkedin.com/newsletters/gyantusker-kiddofin-7023699709567385600/'}>Newsletters</Link>
                    <Link to={'https://kiddofin.my.canva.site/terms-conditions'}>Terms & Condition</Link>
                    <Link to={'https://kiddofin.my.canva.site/privacy-policy'}>Privacy Policy</Link>
                    <Link to={'https://kiddofin.my.canva.site/payment-refund-cancellation-policy'}>Shipping & Refund Policy</Link>
                </div>
                <div className='col'>
                    <figure className='footerLogo'>
                        <img src='./images/kiddofinLogo.png' alt='Kiddofin'/>
                    </figure>
                </div>            
            </div>
            <p className='trulle'>Designed & Developed @ <a href='https://trulle.in/'>Trulle</a></p>
        </>
    )
}