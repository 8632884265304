import './review.css'
import { Underline } from './smallComps'
import styled, {keyframes} from 'styled-components'

export default function Review(){

    const review = [{
        review:"KiddoFin has provided our students with an excellent foundation in financial literacy. The board games and interactive sessions keep the students engaged and eager to learn. We’ve seen a positive impact on their financial awareness and responsibility. This program is invaluable to our school",
        reviewer:"N. Rajagopal, Bharathi Vidyalaya , Meloor",
        rating:5
    }, {
        review:"The KiddoFin workshops have successfully integrated financial literacy with social consciousness. Students learn not only how to manage money but also how to use it responsibly for the betterment of their communities.",
        reviewer:"V. Shanti",
        rating:4
    }, {
        review:"This board game is fantastic! It made learning about finance so much fun. I now know how to make smart money decisions. Playing it with my family was a great experience, and we all learned something new.",
        reviewer:"Anjana Rao",
        rating:5
    }, {
        review:"The activities were highly engaging, and Rohan looked forward to each session. He gained a solid understanding of financial planning, and we’ve seen him take these lessons to heart. The only area for improvement would be more interactive online support post-sessions. Still, we are very pleased with KiddoFin.",
        reviewer:"Rahul Iyer , Rohan’s Father",
        rating:4
    }, {
        review:"The program was well-organized, and our son came out with a newfound interest in finance. We highly recommend it to other parents looking for meaningful activities.",
        reviewer:"Harshita Pandey",
        rating:5
    }, {
        review:"The KiddoFin summer workshop held at our apartment , Bangalore was a fantastic experience for my children. The hands-on kids activities n and real-life examples made financial concepts easy.",
        reviewer:"Suresh Kumar & Geeta Kumar",
        rating:4
    }, {
        review:"Our son had a wonderful time with the fun activities. He learned about saving and spending in a very engaging way. Excellent program!",
        reviewer:"Amaresh Patil",
        rating:5
    }, {
        review:"I enjoyed every chapter of 'Kid's Financial Adventure Map'. It made learning about money fun and easy. Now, I keep a record of my expenses and savings. This book is a must-read for every kid!",
        reviewer:"Rajan",
        rating:5
    }, {
        review:"We absolutely loved the book reading session hosted by KiddoFin. It was not only fun but also inspiring for my child. After the session, my son was so motivated that he started his own weekend lemonade stall and has already begun earning!",
        reviewer:"Lalitha Srinivasan",
        rating:5
    }, {
        review:"After attending KiddoFin's workshop, my daughter surprised us by asking about how to market and deliver her handmade scrunchies instead of her usual interest in starting a YouTube channel. Mangai Maam has truly sparked a new passion in her!",
        reviewer:"Anita Devraj",
        rating:4
    }, {
        review:"Her storytelling was simply amazing! The session was full of laughter and so engaging that we never even noticed the time flying by",
        reviewer:"Veera Ragavan",
        rating:5
    }, {
        review:"KiddoFin's family finance webinar made a complex topic surprisingly simple and enjoyable for both me and my kids",
        reviewer:"Rakesh Rajput",
        rating:4
    }, {
        review:"KiddoFin's team brought a fantastic workshop to Bankgalore School. The students enjoyed the financial literacy activities and learned valuable lessons. We're very impressed!",
        reviewer:"Vikram Kumar",
        rating:5
    }, {
        review:"Our child participated in KiddoFin's workshop at Bankgalore School. It was a fun and educational experience, teaching essential finance concepts through games and activities.",
        reviewer:"Udai Singh",
        rating:5
    }, {
        review:"KiddoFin created an environment where our child could learn about finance while having fun with friends. The team did an excellent job making the sessions enjoyable and educational.",
        reviewer:"Ayesha Banu",
        rating:5
    }, {
        review:"Our child had a great time with the KiddoFin team. They learned about money management while enjoying fun activities with their friends. Highly recommend!",
        reviewer:"Wiliam Nirmal Raj",
        rating:5
    }, ]
    
    const ReviewBox = ({review, reviewer, rating}) =>{
        const rating_array = []
        for(let i=0; i<=4; i++){
            if(rating>0){
                rating_array.push('./images/rating1.svg')
            } else{
                rating_array.push('./images/rating2.svg')
            }
            rating--;
        }
        return(
            <main>
                <p>{review}</p>
                <aside>
                    <figure>
                        {rating_array.map((rate, key)=>(<img src={rate} alt='' key={key}/>))}                        
                    </figure>
                    <h4>{reviewer}</h4>
                </aside>
            </main>
        )
    }

    const left = keyframes`
        0%{
            transform: translateX(0px);
        }
        100%{
            transform: translateX(${(review.length-3) * -420}px);
        }
    `
    const right = keyframes`
        0%{
            transform: translateX(${(review.length-4) * -420}px);
        }
        100%{
            transform: translateX(210px);
        }
    `

    const LeftArticle = styled.article`
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;
        width:${review.length * 420}px;
        animation: ${left} ${review.length*8}s infinite linear;
    `
    const RightArticle = styled.article`
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;
        width:${review.length * 420}px;
        animation: ${right} ${review.length*8}s infinite linear;
    `
    return(
        <div className='reviewMain'>
             <header>
                <h2>Reviews</h2>
                <Underline
                width={50}/>
            </header>
            <div className='reviewSlider'>
                {/* <article style={{width:`${review.length * 420}px`, animation:`${left} 30s infinite linear`}}>
                    {review.map((i)=>(<ReviewBox
                    review={i.review}
                    reviewer={i.reviewer}
                    rating={i.rating}/>))}
                </article>
                <article style={{width:`${review.length * 420}px`, animation:"right 30s infinite linear"}}>
                    {review.map((i)=>(<ReviewBox
                    review={i.review}
                    reviewer={i.reviewer}
                    rating={i.rating}/>))}
                </article> */}

                <LeftArticle>
                    {review.map((i, key)=>(<ReviewBox
                    review={i.review}
                    reviewer={i.reviewer}
                    rating={i.rating}
                    key={key}/>))}
                </LeftArticle>
                <RightArticle>
                    {review.map((i, key)=>(<ReviewBox
                    review={i.review}
                    reviewer={i.reviewer}
                    rating={i.rating}
                    key={key}/>))}
                </RightArticle>
            </div>
        </div>
    )
}