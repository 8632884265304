import Jikina from '../components/jikina'
import { Btn, CallBtn, Marquee, NewsLetter, Stories, Touch, Underline } from '../components/smallComps'
import './home.css'
import { useState, useEffect} from 'react'
import { motion} from 'framer-motion';
import Review from '../components/review';

export default function Home(){
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);
        
    // Notification
    const [notify, setNotify] = useState(false)
    // const [notification, setNotification] =useState([])
    // const notifyCol = collection(db,"Notification")
    // useEffect(()=>{
    //     const getNotification = async()=>{
    //         const data = await getDocs(notifyCol)
    //         setNotification(data.docs.map((doc)=>({...doc.data(), id:doc.id})));
    //     };

    //     getNotification();
    // },[])
    // const notification = [
    //     {
    //         title:'Trulle',
    //         description:"Let's go to trulle",
    //         link:'https://trulle.in'
    //     },
    // ]
    const notification = [
    ]
    return(
        <>
            <div className='wrapper'>                
                {/* <article className='homeLanding'>
                    <aside className='phoneBtn'>
                        <CallBtn/>
                    </aside>
                    <figure>
                        <div className='smallSquare cream'>

                        </div>
                        <div className='square'>
                            <img src='./images/homeLanding.jpg' alt='Kiddofin'/>
                        </div>
                        <div className='smallSquare blue'>

                        </div>
                    </figure>
                    <header>
                        <h1>Empowering the Next Generation of Financial Leaders</h1>
                    </header>
                    <aside>
                        <Btn
                        text={"Learn More"}
                        link={"/about"}/>
                    </aside>
                </article> */}

                <article className='homeLanding2'>
                    <aside className='phoneBtn'>
                        <CallBtn/>
                    </aside>
                    <div className='landing2Conatiner'>
                        <main>
                            <figure>
                                <img src='./images/newLanding1.svg' alt='Kiddofin'/>
                            </figure>
                            <header>
                                <h1>Empowering the Next Generation of Financial Leaders</h1>
                            </header>
                            <div className='landing2Btn'>
                                <Btn
                                text={"Enroll Now"}
                                link={"#touch"}/>
                            </div>
                        </main>
                        <figure>
                            <img src='./images/landing3.svg' alt='Kiddofin'/>
                        </figure>
                    </div>
                </article>

                <article className='homeScroll'>
                    <Marquee
                    children={
                    <a href='http://bit.ly/45JYHvH' className='scroller'>
                        7-Day Online Workshop - One-to-One for Your Child! - Sign up for our personalized 7-day online workshop with our finance expert tutors. Give your child the gift of financial literacy. Register here: http://bit.ly/45JYHvH.
                    </a>
                    } 
                    speed={'20000ms'} 
                    type={'text'}/>
                </article>            
            </div>
            <div className='homeColorBg1'>
                <article className='wrapper homeNote'>
                    <main>
                        <h2>
                        Our goal is to reach 1 million students by 2030, propelling India towards becoming a top three global economic Super power. <br/>
                        </h2>
                        {/* <p>At KiddoFin, we are dedicated to transforming financial education for young minds. Our mission is to empower students with the skills and knowledge they need to navigate their financial futures confidently and help India become a super economic power by 2035.</p> */}
                    </main>
                    <figure>
                        <img src='./images/note.svg' alt='kiddofin'/>
                    </figure>
                </article>                   
            </div>
            <div className='homeColorBg2'>
                <article className='homePrograms'>
                    <header>
                        <h2>Our Programme</h2>
                        <Underline
                        width={35}/>
                    </header>
                    <div className='programContainer'>
                        <motion.main
                            initial={{y:200, opacity:0}}
                            whileInView={{y:0, opacity:1}}
                            transition={{duration:.25, delay:.5}}
                        >
                            <h3>Finchamps Junior</h3>
                            <p>Designed for learners aged 9-12, this program introduces financial literacy basics like saving, budgeting, and understanding money through interactive games and activities, laying a strong educational foundation.</p>
                            <div>
                                <Btn
                                text={"Learn More"}
                                link={'/programme'}/>
                            </div>
                        </motion.main>
                        <motion.main
                            initial={{y:400, opacity:0}}
                            whileInView={{y:0, opacity:1}}
                            transition={{duration:.5, delay:.5}}
                        >
                            <h3>Finchamps Intermediate</h3>
                            <p>Targeted at students aged 12-15, this program delves into advanced financial concepts through activities like mock board meetings, investment simulations, and budget planning, bridging the gap between basic knowledge and practical application.</p>
                            <div>
                                <Btn
                                text={"Learn More"}
                                link={'/programme'}/>
                            </div>
                        </motion.main>
                        <motion.main
                            initial={{y:100, opacity:0}}
                            whileInView={{y:0, opacity:1}}
                            transition={{duration:.5, delay:.5}}
                        >
                            <h3>Finchamps Advanced</h3>
                            <p>For high school students aged 15-18, this program covers in-depth topics such as advanced investment strategies, entrepreneurship, and financial planning, equipping students with the skills for financial independence and responsibility.</p>
                            <div>
                                <Btn
                                text={"Learn More"}
                                link={'/programme'}/>
                            </div>
                        </motion.main>
                        <motion.main
                            initial={{y:150, opacity:0}}
                            whileInView={{y:0, opacity:1}}
                            transition={{duration:.25, delay:.5}}
                        >
                            <h3>5-Hour Workshop</h3>
                            <p>These workshops offer an in-depth exploration of specific financial topics, tailored for different age groups, and provide practical, actionable insights.</p>
                            <div>
                                <Btn
                                text={"Learn More"}
                                link={'/programme'}/>
                            </div>
                        </motion.main>
                        <motion.main
                            initial={{y:100, opacity:0}}
                            whileInView={{y:0, opacity:1}}
                            transition={{duration:.5, delay:.5}}
                        >
                            <h3>Online Classes
                            </h3>
                            <p> Flexible, comprehensive financial education accessible online, covering a wide range of topics suitable for various age groups, designed to fit diverse schedules and learning needs.</p>
                            <div>
                                <Btn
                                text={"Learn More"}
                                link={'/programme'}/>
                            </div>
                        </motion.main>
                    </div>
                </article>
                <article className='MisVis'>
                    {/* <motion.div className='MisVisContainer'
                        initial={{x:-200, opacity:0}}
                        whileInView={{x:0, opacity:1}}
                        transition={{duration:.5, delay:.5}}
                    > */}
                    <div className='MisVisContainer'>
                        <main>
                            <h2>Vision</h2>
                            <p>To forge a future where every Indian child is a cornerstone in our nation's ascent to global economic leadership, making a profound impact on society with their unmatched financial acumen.</p>
                        </main>
                        <figure>
                            <img src='./images/vision.png' alt='vision'/>
                        </figure>
                    </div>
                    {/* </motion.div> */}
                    {/* <motion.div className='MisVisContainer'
                        initial={{x:200, opacity:0}}
                        whileInView={{x:0, opacity:1}}
                        transition={{duration:.5, delay:.5}}
                    > */}
                    <div className='MisVisContainer'>
                        <main>
                            <h2>Mission</h2>
                            <p>To deliver cutting-edge financial education that equips students to master complex financial dynamics and drive meaningful change</p>
                        </main>
                        <figure>
                            <img src='./images/mission.png' alt='vision'/>
                        </figure>
                    </div>
                    {/* </motion.div> */}
                </article> 
            </div>
            <Review/>   
            <div className='jikinaContainer'>
                <Jikina/>
            </div>
            <article className='homePublish'>
                <header>
                    <h2>Published on</h2>
                    <Underline
                    width={35}/>
                </header>
                <figure>
                    <img src='./images/publish1.svg' alt='allevents'/>
                    <img src='./images/publish2.svg' alt='ANI'/>
                    <img src='./images/publish3.svg' alt='Business Standard'/>
                    <img src='./images/publish4.svg' alt='Hindustan Times'/>
                    <img src='./images/publish5.svg' alt='The Print'/>
                    <img src='./images/publish6.svg' alt='explocity'/>
                </figure>
            </article>
            <article className='homeWhy'>
                <header>
                    <h2>Why KiddoFin?</h2>
                    <Underline
                    width={35}/>
                </header>
                <main>
                    <details>
                        <summary>
                            <h4>Real-World Applications</h4>
                            <h5>+</h5>
                        </summary>
                        <p>Our programs are designed to prepare students for real-world financial challenges. We focus on practical skills that students can use in their everyday lives, ensuring that they are well-equipped to manage their finances.</p>
                    </details>

                    <details>
                        <summary>
                            <h4>Expert Guidance</h4>
                            <h5>+</h5>
                        </summary>
                        <p>Our team of experienced educators and financial experts is dedicated to providing high-quality financial education. We are committed to helping students succeed and reach their full poten</p>
                    </details>

                    <details>
                        <summary>
                            <h4>Innovative Teaching Methods</h4>
                            <h5>+</h5>
                        </summary>
                        <p>We use a variety of innovative teaching methods to make financial education enjoyable and effective. Our approach includes interactive classes, gamified learning experiences, and real-world applications of financial concepts.</p>
                    </details>

                    <details>
                        <summary>
                            <h4>Community and Support</h4>
                            <h5>+</h5>
                        </summary>
                        <p>Our team of experienced educators and financial experts is dedicated to providing high-quality financial education. We are committed to helping students succeed and reach their full potential</p>
                    </details>

                    <details>
                        <summary>
                            <h4>Customizable Courses</h4>
                            <h5>+</h5>
                        </summary>
                        <p>At KiddoFin, we understand that each student, school, and community has unique needs. That's why we offer customizable courses tailored to meet specific requirements. Whether you need a comprehensive annual program, focused workshops, or specialized coaching sessions, we can adapt our offerings to suit your needs. This flexibility ensures that our programs are effective and relevant, providing the best possible learning experience for all participants.</p>
                    </details>
                </main>
            </article>
            <article className='homeCertificate'>
                <figure>
                    <img src='./images/homeCertificate.svg' alt='Kiddofin'/>
                </figure>
                <div>
                    <main>
                        <h3>General Certificate Programs</h3>
                        <p>Empower young minds with essential financial skills through our comprehensive certificate courses.</p>
                    </main>
                    <main>
                        <h3>Children’s Entrepreneurial Expo & Quiz Competitions</h3>
                        <p>Immerse your child in our comprehensive FinChamp exclusive 12-day financial literacy class, tailored for three distinct age groups: 6th-8th grade, 9th-10th grade.</p>
                    </main>
                    <main>
                        <h3>Financial Literacy Programs</h3>
                        <p>Build a strong financial foundation for students with our tailored financial literacy workshops.</p>
                    </main>
                    <main>
                        <h3>Finance Career Guidance </h3>
                        <p>Prepare students for a successful career in finance with expert-led guidance tailored for schools and colleges.</p>
                    </main>
                    <main>
                        <h3>Preparation for Finance Competitive Exams</h3>
                        <p>Equip students with the knowledge and strategies needed to excel in finance-related competitive exams.</p>
                    </main>
                </div>
            </article>
            <div className='wrapper'>
                <article className='founder'>
                    <header className='forMobFounderHead'>
                        <h2>Hear From Founder
                            <Underline
                        width={50}/></h2>
                    </header>
                    <div className='founderImage'>
                        <figure>
                            <img src='./images/founder.svg' alt='Founder'/>
                        </figure>
                        <h3>Mangaiyarkarasi Manoharan<br/>B.E(CSE)., MBA., (HR)</h3>
                        <h4>Chartered Banker(United Kingdom),<br/>Certified Associate of Indian Institute of Bankers,<br/>Certified Information Systems Auditor(CISA)</h4>
                    </div>
                    <div className='founderSays'>
                        <header>
                            <h2>Hear From Founder
                                <Underline
                            width={50}/></h2>
                        </header>
                        <p>
                        As a mother and with extensive experience interacting with the public across India, I deeply believe that KiddoFin is uniquely positioned to meet the "Needs & Wants "  we're dedicated to teaching Gen Z and Alpha kids in a way that truly connects with how they love to learn.
                        </p>
                    </div>
                </article>
                <article className='board'>
                    <h2>Our Board of Advisors</h2>
                    <div className='members'>
                        <div className='member'>
                            <figure>
                                <img src='./images/board1A.svg' alt='Founder'/>
                            </figure>
                            <h3>Mrs. Devika Kannappa - B.com., FCA.</h3>
                            <h4>Devika & Associates, CA,Auditors,Tax consultants Bengaluru</h4>
                        </div>
                        <div className='member'>
                            <figure>
                                <img src='./images/board2A.svg' alt='Founder'/>
                            </figure>
                            <h3>Mr. Ashwin Kumar Mohan - B.E., M.B.A.,CAIIB</h3>
                            <h4>Fintech & Risk Management (IIM Lucknow),Moody's Credit Analyst - VicePresident, Team Startup , a leading Bank, Bengaluru</h4>
                        </div>
                        <div className='member'>
                            <figure>
                                <img src='./images/board3A.svg' alt='Founder'/>
                            </figure>
                            <h3>Mr. Mageshkumar Balasubramani - B.E.,MS .,CEH,CISSP,CCIE</h3>
                            <h4>Cyber Security Expert, BFSI Industry</h4>
                        </div>
                    </div>
                </article>
                <article className='homeNewsletter'>
                    <header>
                        <h2>Linkedin NewsLetter</h2>
                    </header>
                    <div className='letterContainer'>
                        <NewsLetter
                        title={"Teenager Ravi & Budget discussion Inflation & Indexation"}
                        // image={'./images/newsletter.png'}                        
                        image={'https://media.licdn.com/dms/image/D5612AQFRuN3zq86Vvw/article-cover_image-shrink_720_1280/0/1722957308107?e=1728518400&v=beta&t=yr680UlZ4i1Z4aDT4GAfBHsl7aiwslP_3rP_l1ukNEQ'}
                        publish={"06/08/2024"}
                        link={"https://www.linkedin.com/pulse/teenager-ravi-budget-discussion-inflation-indexation-manoharan-ssy6c/?trackingId=gtMBSzWqQQqFt0dJkchz7Q%3D%3D"}/>
                        <NewsLetter
                        title={"Envious Eesha and Wise Thatha"}
                        image={'https://media.licdn.com/dms/image/D5612AQFwUzzRYLtLYw/article-cover_image-shrink_720_1280/0/1722860900935?e=1728518400&v=beta&t=7tKDo3K903Z36Pu7QczCCAnKS3SLdOI-IxmPoNlSboc'}
                        publish={"05/08/2024"}
                        link={"https://www.linkedin.com/pulse/envious-eesha-wise-thatha-mangaiyarkarasi-manoharan-vavnc/?trackingId=IXOOrQEKTgWgv23QDTeOTQ%3D%3D"}/>
                        <NewsLetter
                        title={"The Tale of Aisha and the Family Business"}
                        image={'https://media.licdn.com/dms/image/D5612AQFWiNMrfIYtxA/article-cover_image-shrink_720_1280/0/1722452631300?e=1728518400&v=beta&t=rVyijsioOSOCif1m2_VlajSQGGci9C1nK2ojwT0rXRE'}
                        publish={"01/08/2024"}
                        link={"https://www.linkedin.com/pulse/tale-aisha-family-business-mangaiyarkarasi-manoharan-bgnmc/?trackingId=AYjZdnfWRGKkX6Micji8Ow%3D%3D"}/>
                        <NewsLetter
                        title={"Bedtime Story: The Path to Knowledge and Financial Wisdom 🌟📚💰"}
                        image={'https://media.licdn.com/dms/image/D5612AQEjhKnM_yAMMg/article-cover_image-shrink_720_1280/0/1721992060504?e=1728518400&v=beta&t=f232j2REHvuT0cUuGpkIFBakZXj6R0aEXN5X1Hb9w34'}
                        publish={"28/07/2024"}
                        link={"https://www.linkedin.com/pulse/bedtime-story-path-knowledge-financial-wisdom-manoharan-okqoc/?trackingId=etFvhUGlQR2vLcVKGsqw8w%3D%3D"}/>
                    </div>
                </article>
                <Touch id="touch"/>
            </div>
            <div className={notification.length>0?'show notificationContainer':'hide'}>
                <div className={notify?'hideIcon notificationIcon':'showIcon notificationIcon'} onClick={()=>setNotify(!notify)}>
                    <img src='../images/notification.svg' alt='notif'/>
                </div>
                <div className={notify?'displayContainer notification':'noDisplayContainer notification'}>
                    <header>
                        <h3>Notification</h3>
                        <img src='../images/close.svg' alt='close' onClick={()=>setNotify(!notify)}/>
                    </header>
                    <div className='notice'>
                        {notification.map((notify)=>(
                            <article>
                                <a href={notify.link}>
                                    <main>
                                        <h4>{notify.title}</h4>
                                        <p>{notify.description}</p>
                                    </main>
                                    <figure >
                                        <img src='../images/notifyArrow.svg' alt='arrow'/>
                                    </figure>
                                </a>
                            </article>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}