import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import NavWeb from './components/navWeb';
import Footer from './components/footer';
import About from './pages/about';
import Programs from './pages/programs';
import Source from './pages/source';
import Contact from './pages/contact';
import NotFound from './pages/notFound';
import Gallery from './pages/gallery';

function App() {
  return (
    <>
      <NavWeb/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/programme' element={<Programs/>}/>
        <Route path='/downloads' element={<Source/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/gallery' element={<Gallery/>}/>

        <Route path='/*' element={<NotFound/>}/>
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
