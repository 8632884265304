import { Touch } from '../components/smallComps'
import './contact.css'
import {  useEffect } from 'react'

export default function Contact(){
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);
 
    return(
        <>
            <div className='wrapper'>
                <article className='contactLanding'>
                    <figure className='contactImage'>
                        <img src='./images/contact.png' alt='Contact Kiddofin'/>
                    </figure>
                    <main>
                        <header>
                            <h2>Feel Free to Reach Us</h2>
                        </header>
                            <div className='contactRow'>
                                <div className='contactIcon'>
                                    <a href='mailto:Info@ kiddofin.com'>
                                        <img src='./images/letter.svg' alt='Mail Kiddofin'/>
                                        <p>Mail Us</p>
                                    </a>
                                </div>
                                <div className='contactList'>
                                    <p>Info@ kiddofin.com</p>
                                    <p>tuskerrayz@gmail.com</p>
                                </div>
                            </div>

                            <div className='contactRow'>
                                <div className='contactIcon'>
                                    <a href='tel:+916366562433'>
                                        <img src='./images/phoneIcon.svg' alt='Mail Kiddofin'/>
                                        <p>Call Us</p>
                                    </a>
                                </div>
                                <div className='contactList'>
                                    <p>+91 63665 62433</p>
                                    <p>+91 82709 82433</p>
                                </div>
                            </div>
                    </main>
                </article>
                <Touch/>
            </div>
        </>
    )
}