import { Link, useLocation } from 'react-router-dom'
import './navWeb.css'
import { useState, useEffect } from 'react';

export default function NavWeb (){

    const location = useLocation()
    const path = location.pathname.substring(1)
// To Choose NavBar
    const [navbar, setNavbar] = useState(true)
    const navSelect = () =>{
        if(window.innerWidth<768){
            setNavbar(false)
        }else{
            setNavbar(true)
        }
    }
    useEffect(() => {
        if (window.innerWidth<768){
            setNavbar(false)
        }else{
            setNavbar(true)
        }
            window.addEventListener('resize', navSelect);
            window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
            window.removeEventListener('resize', navSelect);
        };
    }, []);


    // const [scrollPosition, setScrollPosition] = useState(0);
    const [pageScroll, setpageScroll] = useState(false);
    const handleScroll = () => {
        const position = window.scrollY;
        // setScrollPosition(position);
    
        if (position >= 100) {
            setpageScroll(true) 
        } else {
            setpageScroll(false) 
        }
      };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Menu check
    const [menuVisible, setMenuVisible] = useState(false)

    return(
        <>
        <nav className='navContainer'>
            <div className={pageScroll?'navweb navScrolled':'navweb'}>
                <figure>
                    <Link to='/'>
                        <img src='./images/kiddofinLogo.png' alt=''/>
                        <h2>Kiddofin</h2>
                    </Link>                
                </figure>
                <main className={navbar?'show':'hide'}>
                    <Link to='/' >Home</Link>
                    <Link to='/about' >About Us</Link>
                    <Link to='/programme' >Programme</Link>
                    <Link to='/gallery' >Gallery</Link>
                    <Link to='/downloads' >Downloads</Link>
                    <Link to='/contact' >Contact Us</Link>
                </main>
                {/* <div className='menuIconContainer' style={menuVisible?{position:"fixed"}:{position:"absolute"}}> */}
                    <div className='menuBtn' onClick={()=>setMenuVisible(!menuVisible)}>
                        <div className={menuVisible?'bar bar1':'bar'}/>
                        <div className={menuVisible?'bar bar2':'bar'}/>
                        <div className={menuVisible?'bar bar3':'bar'}/>
                    </div>
                {/* </div> */}
            </div>   
            <div className={menuVisible?'dropContainer':'hideDrop'}>
                <div className='horizontal'>

                </div>
                <div className='vertical'>
                    <div className='dropCol'>
                        <Link to='/' onClick={()=>setMenuVisible(!menuVisible)} className={path===''?'indicator':''}>Home</Link>
                        <Link to='/about' onClick={()=>setMenuVisible(!menuVisible)} className={path==='about'?'indicator':''}>About Us</Link>
                        <Link to='/programme' onClick={()=>setMenuVisible(!menuVisible)} className={path==='programme'?'indicator':''}>Programme</Link>
                        <Link to='/gallery' onClick={()=>setMenuVisible(!menuVisible)} className={path==='gallery'?'indicator':''}>Gallery</Link>
                        <Link to='/downloads' onClick={()=>setMenuVisible(!menuVisible)} className={path==='downloads'?'indicator':''}>Downloads</Link>
                        <Link to='/contact' onClick={()=>setMenuVisible(!menuVisible)} className={path==='contact'?'indicator':''}>Contact Us</Link>
                    </div>
                    <div className='dropCol dropOther'>
                        <Link to={'https://www.linkedin.com/newsletters/gyantusker-kiddofin-7023699709567385600/'} onClick={()=>setMenuVisible(!menuVisible)}>Newsletters</Link>
                        <Link to={'https://kiddofin.my.canva.site/terms-conditions'} onClick={()=>setMenuVisible(!menuVisible)}>Terms & Condition</Link>
                        <Link to={'https://kiddofin.my.canva.site/privacy-policy'} onClick={()=>setMenuVisible(!menuVisible)}>Privacy Policy</Link>
                        <Link to={'https://kiddofin.my.canva.site/payment-refund-cancellation-policy'} onClick={()=>setMenuVisible(!menuVisible)}>Shipping & Refund Policy</Link>
                    </div>
                </div>            
            </div>                     
        </nav>
        {/* <div className='menuIconContainer' style={menuVisible?{position:"fixed"}:{position:"absolute"}}>
            <div className='menuBtn' onClick={()=>setMenuVisible(!menuVisible)}>
                <div className={menuVisible?'bar bar1':'bar'}/>
                <div className={menuVisible?'bar bar2':'bar'}/>
                <div className={menuVisible?'bar bar3':'bar'}/>
            </div>
        </div> */}
        </>
    )
}