import { useState } from "react"
import { Underline } from "../components/smallComps"
import "./gallery.css"
import Masonry from "react-masonry-css"

export default function Gallery(){

    const breakpoints={
        default:4,
        1000:3,
        700:2,
    }

    const galleryContent = [{
        image:"./images/gallery/gallery1.jpg",
        title:"Kiddofin",
        context:"Toastmaster club Prestige Lakeside apartment Bangalore Quiz competition",
        date:"08/08/2024",
        link:""
    }, {
        image:"./images/gallery/gallery2.jpg",
        title:"Kiddofin",
        context:"Finance workshops, Bangalore  2024",
        date:"24/05/2024",
        link:""
    }, {
        image:"./images/gallery/gallery3.jpg",
        title:"Kiddofin",
        context:"7 Days Finchamp  for 6thGrade to PU college students  @ LVHS Bangalore",
        date:"24/05/2024",
        link:""
    }, {
        image:"./images/gallery/gallery4.jpg",
        title:"Kiddofin",
        context:"Sponsorship, Workshop  ,Mentor Meeting 2024",
        date:"24/05/2024",
        link:""
    }, ]

    const [showPop, setShowPop] = useState(false)
    const [popContent, setPopContent] = useState(galleryContent[0])
    return(
        <>
        <div className="wrapper galleryMain">
            <header>
                <div>
                    <h2>Our Gallery</h2>
                    <Underline
                    width={45}/>
                </div>
            </header>            
            <div className="gallery">
                <Masonry
                breakpointCols={breakpoints}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {
                    galleryContent.map((item, key)=>(
                        <div className="galleryContainer" key={key} onClick={()=>{setShowPop(!showPop);setPopContent(item)}}>
                            <figure>
                                <img src={item.image} alt="gallery"/>                        
                            </figure>
                            <p>{item.context}</p>
                        </div>
                    ))
                }
                </Masonry>
            </div>
        </div>
        <div className={showPop?"wrapper galleryPop show":"wrapper galleryPop hide"}>
            <div className="popBG"></div>
            <h5 className="closePop" onClick={()=>setShowPop(!showPop)}>x</h5>
            <figure>
                <img src={popContent.image} alt={popContent.image}/>
            </figure>
            <main>
                <h3>{popContent.title}</h3>
                <p>{popContent.context}</p>
                <aside>
                    <h4>Posted on- {popContent.date}</h4>
                    <a href={popContent.link}>
                        Open
                        <img src="./images/redirect.svg" alt=">"/>
                    </a>
                </aside>
            </main>
        </div>
        </>
    )
}