import { Link } from 'react-router-dom'
import './smallComps.css'
import { useState } from 'react'

export function Btn (props){
    const {text, link} = props
    
    return(
        <a className='btnContainer' href={link?link:null}>
            <p className='btnText'>{text}</p>
            <div className='btnImage'>
            </div>
            <span></span>
        </a>
    )
}

export function BtnSubmit (props){
    const {text} = props
    return(
        <button className='btnContainer subContainer' >
            <p className='btnText'>{text}</p>
            <div className='btnImage subImage'>
            </div>
            <span></span>
        </button>
    )
}

export function Marquee({ children, speed }){
    return(
        <div className='marquee' style={{animationDuration: `${speed}`}}>                   
            {children}
            {children}
            {children}
            {children}
            {children}
            {children}
            {children}            
        </div>
    );
}

// UnderLine
export function Underline ({width}){
    return(<span className='underline' style={{width:`${width}%`}}></span>)
}

// Program
export function Program ({props}){
    // const{title, content, age, certificate, link} = props
    return(
        <div className='program'>
            <h3 className='programTitle'>{props.title}</h3>
            <div className='programCard'>
                <figure className='programImage'>
                    <img src={`./images/program/${props.image}.png`} alt={``}/>
                </figure>
                <main className='programContext'>
                    {/* <h3>{props.title}</h3> */}
                    <p className='programDetails'>{props.content.map((item)=>(<><p>{item}</p><br/></>))}</p>
                    <ul className='programList'>{props.bullets.map((list, index)=>(<>
                        {Object.entries(list).map(([key, value])=>(<li><strong>{key}: </strong>{value}</li>))}
                    </>))}</ul>
                    <div className='programSpecs'>
                        <div className='programSpecsCol AgeSpec'>
                            <div className='programSpecsTitle'>
                                <figure>
                                    <img src='./images/age.svg' alt='age'/>
                                </figure>
                                <p>Age Group</p>
                            </div>
                            <div className='programSpecsRow'>
                                <p>{props.age}</p>
                            </div>
                        </div>
    
                        <div className='programSpecsCol'>
                            <div className='programSpecsTitle'>
                                <figure>
                                    <img src='./images/certificate.svg' alt='age'/>
                                </figure>
                                <p>Certification</p>
                            </div>
                            <div className='programSpecsRow'>
                            {props.certificate.map((cert)=>(<p>{cert}</p>))}
                            </div>
                        </div>
                    </div>
                    <aside>
                        <div>
                        <Btn
                        text={"Enroll Now"}
                        link={props.link}/>
                        </div>                                    
                    </aside>
                </main>
            </div>
        </div>
)
}

// Touch
export function Touch(){
    const [submit, setSubmit] = useState(false)
    const [enquiry, setEnquiry] = useState({
        name:'',
        mobile:'',
        mail:'',
        location:'',
        school:'',
        who:'',
        note:''
    })
    const enqChange=(e)=>{
        const {name, value}=e.target
        setEnquiry((preData)=>({
            ...preData,
            [name]:value
        }))
    }
    const submitEnq = async(e) =>{
        e.preventDefault()
        setSubmit(!submit)
        console.log(enquiry)
        setEnquiry({
            name:'',
            mobile:'',
            mail:'',
            location:'',
            school:'',
            who:'',
            note:''
        })
    }
    return(
        <>
        <article className='touch' id='touch'>
            <div className='touchNote'>
                <h2>Get in Touch</h2>
                <p>
                Unleash Financial Brilliance with KiddoFin!
                <br/><span></span><br/>
                Empower your children with essential financial skills through KiddoFin’s  school collaborations, including finance expos , engaging club activities & Online classes.
                <br/><span></span><br/>
                Prepare for prestigious exams like NFLAT, National Finance Olympiad, and International Finance Olympiad with our expert-led courses.
                <br/><span></span><br/>
                Interested? Share the details below
                Our team will contact you. Let's build a financially savvy generation together!
                </p>
            </div>
            <div className='touchNote mobTouch'>
                <h2>Let’s Discuss</h2>
                <p>
                kiddofin, Now awaits for you to help accomplish your Financial goals and train for NFLAT Exams.
                </p>
            </div>
            <div className='touchContact'>
                <h2>Lets Talk</h2>
                <form onSubmit={submitEnq}>
                    <div className='inputContainer'>
                        <label>Name*</label>
                        <input
                            type='text'
                            name='name'
                            value={enquiry.name}
                            onChange={enqChange}
                            required
                        />
                    </div>
                    <div className='inputContainer'>
                        <label>Mobile Number*</label>
                        <input
                            type='tel'
                            name='mobile'
                            value={enquiry.mobile}
                            onChange={enqChange}
                            required
                        />
                    </div>
                    <div className='inputContainer'>
                        <label>Mail ID</label>
                        <input
                            type='mail'
                            name='mail'
                            value={enquiry.mail}
                            onChange={enqChange}
                        />
                    </div>
                    <div className='inputContainer'>
                        <label>Location*</label>
                        <input
                            type='text'
                            name='location'
                            value={enquiry.location}
                            onChange={enqChange}
                            required
                        />
                    </div>
                    <div className='inputContainer'>
                        <label>School Name*</label>
                        <input
                            type='text'
                            name='school'
                            value={enquiry.school}
                            onChange={enqChange}
                            required
                        />
                    </div>
                    <div className='inputContainer'>
                        <label>Who are you?*</label>
                        {/* <input
                            type='text'
                            name='who'
                            value={enquiry.who}
                            onChange={enqChange}
                            required
                        /> */}
                        <select
                            name='who'
                            value={enquiry.who}
                            onChange={enqChange}
                            required
                        >
                            <option hidden value={""}>Your are</option>
                            <option value={"School Teacher"}>School Teacher</option>
                            <option value={"Principal"}>Principal</option>
                            <option value={"School representative"}>School representative</option>
                            <option value={"parent"}>parent</option>
                            <option value={"Student"}>Student</option>
                            
                        </select>
                    </div>
                    <div className='inputContainer'>
                        <label>Notes</label>
                        <input
                            type='text'
                            name='note'
                            value={enquiry.note}
                            onChange={enqChange}
                        />
                    </div>
                    <div className='inputContainer submitContainer'>
                        <BtnSubmit
                        text={"Submit"}/>
                    </div>
                </form>
            </div>
        </article>
        <div className={submit?'alertContainer show':'alertContainer hide'}>
            <div className='alertBG'></div>
            <div className='alert'>
                <p>Thanks for Contacting us.<br/>Our team will contact you soon</p>
                <button onClick={()=>setSubmit(!submit)}>Close</button>
            </div>
        </div>
        </>
    )
}

// Stories
export function Stories(){
    // Storys....
    const stories=[{
            image:'./images/story1.png',
            context:'Business Luminaries Shine at Indian Business Excellence Awards 2024!',
            link:'https://www.aninews.in/news/business/business-luminaries-shine-at-indian-business-excellence-awards-202420240305180426/'
        },{
            image:'./images/story2.png',
            context:'Student Success Story',
            link:"https://g.co/kgs/QcQNmBd"
        },{
            image:'./images/story3.png',
            context:'Student Success Story',
            link:'https://g.co/kgs/ovof5v5'
        },{
            image:'./images/story4.png',
            context:'Linkedin Story',
            link:'https://www.linkedin.com/newsletters/gyantusker-kiddofin-7023699709567385600/'
        },
    ]      
    const [homeStoryIndex, setHomeStoryIndex] = useState(0)
    const [storySlideMove, setStorySlideMove] = useState('translateX(0px)')
    const preHomeStory =()=>{
        if(homeStoryIndex>0){
            setStorySlideMove(`translateX(-${250*(homeStoryIndex-1)}px)`)
            setHomeStoryIndex(homeStoryIndex-1)
        }
    }
    const nextHomeStory =()=>{
        if(homeStoryIndex<stories.length-1){
            setStorySlideMove(`translateX(-${250*(homeStoryIndex+1)}px)`)
            setHomeStoryIndex(homeStoryIndex+1)
        }
    }
    return(
        <article className= 'homeStories'>
            <main>
                <h2>Our Success Stories</h2>
                <p className="homeStoryDes">Financially educated youth indians</p>
                <div>
                    <Btn
                    text={"Learn More"}
                    link={"/about"}
                    />
                </div>                    
            </main>
            <aside>
                <div className='StorySlider' style={{width:`${stories.length*360}px`, transform:`${storySlideMove}`}}>
                    {stories.map((story, key)=>(
                        <a href={story.link} className='storyContainer' key={key}>
                            <figure>
                                <img src={story.image} alt='story'/>
                            </figure>
                            <p>{story.context}</p>
                        </a>
                ))}                        
                </div>
                <div className='storyNavigator'>
                    <figure className='leftArrow' onClick={preHomeStory}>
                        <img src={homeStoryIndex===0?'./images/navArrowDisable.svg':'./images/navArrowEnable.svg'} alt='left'/>                            
                    </figure>
                    <figure className='rightArrow' onClick={nextHomeStory}>
                        <img src={homeStoryIndex>stories.length-2?'./images/navArrowDisable.svg':'./images/navArrowEnable.svg'} alt='right'/>                            
                    </figure>
                </div>
            </aside>
        </article>
    )
}

// CallBtn
export function CallBtn (){
    return(
        <a href='https://api.whatsapp.com/send/?phone=916366562433&text&type=phone_number&app_absent=0' className='actionBtn toCall'>
            <img src='../images/whatsapp.svg' alt='phone'/>
            {/* <a href='tel:+917090736933'>+91 7090736933</a> */}
            <button>
                +91 63665 62433    
            </button>
        </a>
    )
}

// NewsLetter
export function NewsLetter (props){
    return(
        <div className='newsLetter'>
            <h3>{props.title}</h3>
            <p>Publish {props.publish}</p>
            <img src={props.image} alt='NewsLetter' className='newsImage'/>
            <a href={props.link}>
                <h2>Read Now</h2>
                <img src='./images/shareNews.svg' alt='sh'/>
            </a>
        </div>
    )
}